.text {
  font-size: 18px;
}
.footer {
  position: absolute;
  bottom: 20px;
}
.logo {
  height: 130px;
  width: auto;
}
